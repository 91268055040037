.tab-success.active {
    background-color:  #198754 !important;
    color: white !important;
}

.tab-success {
    color: #198754 !important;
    border: 1px solid #198754 !important;
    width: 98% !important;
}

.tab-success.active .badge {
    background-color: white !important;
    color: #198754 !important;
}

.tab-warning.active {
    background-color:  #ffc107 !important;
    color: white !important;
}

.tab-warning {
    color: #ffc107 !important;
    border : 1px solid #ffc107 !important;
    width: 98% !important;
}

.tab-warning.active .badge {
    background-color: white !important;
    color: #ffc107 !important;
}

.tab-danger.active {
    background-color:  #dc3545 !important;
    color: white !important;
}

.tab-danger {
    color: #dc3545 !important;
    border: 1px solid #dc3545 !important;
    width: 100% !important;
}

.tab-danger.active .badge {
    background-color: white !important;
    color: #dc3545 !important;
}

.date-clear-button::after {
    content:'✖';
    background-color: transparent;
    color: #000000;
}