.view-container {
    height: calc(100vh - 135px);
    overflow-y: scroll;
}

.container {
  max-width: 90vw;
}

@media (max-width: 992px) {
  .container { 
      max-width: 100%;
  }
}

//scrollbar

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  //navbar

  .navbar-logo {
      max-width: 150px;
      max-height: 40px;
  }

  //no line break

  .single-line {
    white-space: nowrap
  }

  th {
    padding: 0.5rem 0.5rem;
  }

  .text-white td {
    color: white !important;
  }

  .form-control-plaintext {
    padding-left: .25rem;
  }

  .form-control-plaintext:hover::after {
    content :'edit';
    right: 10px;
    position: absolute;
  }

  .form-control-plaintext:not([readonly]):hover {
    border: 1px solid black;
    border-radius: .25rem;
  }

  .nav-tabs {
    margin-bottom: 0px !important;
  }

  .spinner {
    animation: spin infinite 5s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

  .pointer {
    cursor : pointer;
  }

  .article-select:hover {
    font-weight: bold;
    color: #0d6efd
  }

  .react-datepicker__header {
    background-color: transparent;
  }

  .react-datepicker__day--selected {
    background-color: #0d6efd
  }
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//datatables styling

//smaller min width for columns

.rdt_TableHeadRow div, .rdt_TableBody div div {
  min-width: 65px;
}
