@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    div {
        break-inside: avoid;
      }

      .rdt_TableHeadRow div, .rdt_TableBody div div {
        min-width: 20mm;
      }
}

@page {
    size: auto;
    margin: 10mm;
}


