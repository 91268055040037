.Login {
  padding: 60px 0;
  height: 100vh !important;
  color: white;
}

.Login label {
  width: auto;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: black;
}

.login-form-container {
  text-align: center;
  margin:  auto;
  width: 420px;
}

.login-form-container .card {
  background-color: rgba(255,255,255,0.25) !important
}

.login-form-container form input {
  text-align: center;
}
  